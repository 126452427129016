<template>
	<div>
		<div class="form-group">
			<label for="model_label">{{ $t('monte.modele_contrat') }} *</label>
			<e-select
				:disabled="disabled"
				v-model="modele"
				id="model_id"
				track-by="model_id"
				label="model_label"
				:placeholder="$t('monte.rechercher_modele')"
				:selectedLabel="$t('global.selected_label')"
				:options="modeles"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingModele"
				:sortable="false"
				:class="{ 'is-invalid': errors && errors.indexOf('modele') > -1 }"
			></e-select>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import ModelMixin from "@/mixins/Model.js"

	export default {
		name: 'Modele',
		mixins: [Navigation, ContractMixin, ModelMixin],
		props:{
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			errors: {
				type: Array,
				default: () => ( [] )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
            autoSelectDefaultCa: {
                type: Boolean,
				default: () => ( true )
            }
		},
		data () {
			return {
                modeles: [],
                loadingModele: false,
			}
		},

		computed: {
			modele: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				await this.loadModeles()
			},

			async loadModeles() {

				// this.modele = null
				let model_selected = null
				this.modeles = []
				if(this.contractconfig_id != 0 && this.contractconfig_id != null) {
					this.loadingModele = true
					this.modeles = await this.loadAllModel(this.contractconfig_id)

                    if(this.autoSelectDefaultCa) {
						model_selected = this.modeles.find(m => {
							return m.default_ca === true
						})
                    } 

                    if(model_selected == null) {
    					model_selected = this.modeles[0]
                    }

					this.loadingModele = false
				}

				this.modele = model_selected
			}
		},
		watch: {
			contractconfig_id(){
				//Je vais définir le nouveau contract_config puisque l'étalon a changé
				this.loadModeles()
			}
		},

		components: {

		}
	}

</script>